import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  error: false,
  showError: false,
  message: "",
  loading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    showError(state, { payload }) {
      state.error = true;
      state.message = payload?.data?.message || payload?.error?.data?.message;
      state.showError = true;
    },
    hideError(state) {
      state.error = false;
      state.message = "";
      state.showError = false;
    },
  },
});

export const { showError, hideError } = loadingSlice.actions;
export default loadingSlice.reducer;
