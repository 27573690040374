import React from "react";
import {
  faEnvelope,
  faUserTie,
  faCalendarDays,
  faCheckToSlot,
  faChartSimple,
  faFile,
  faAddressCard,
  faGear,
  faFileExcel,
  faBook,
  faPrint,
  faTags,
  faTruckFast,
  faUserAltSlash,
  faFileArchive,
  faFileLines,
  faCalendarWeek,
  faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";

const LsgdDashboard = React.lazy(() => import("../pages/LSGD/dashboard"));
const Monthlypermit = React.lazy(() => import("../pages/LSGD/monthlyPermit"));
const LorryRegistration = React.lazy(() =>
  import("../pages/LSGD/lorryRegistration")
);
const WorkersRegistration = React.lazy(() =>
  import("../pages/LSGD/workersRegistration")
);
const SaleReport = React.lazy(() => import("../pages/LSGD/viewSalereport"));
const SalereportSpotbooking = React.lazy(() =>
  import("../pages/LSGD/viewSalereport(sportbookin)")
);

export const LSGDRoutes = [
  {
    name: "Monthly Permit",
    component: <Monthlypermit />,
    role: "pc",
    path: "/",
    mainMenu: true,
    icon: faCalendarPlus,
  },

  {
    name: "Spot Sale Report",
    component: <SalereportSpotbooking />,
    role: "pc",
    path: "/SalereportSpotbooking",
    mainMenu: true,
    icon: faFileLines,
  },

  {
    name: "Sale Report",
    component: <SaleReport />,
    role: "pc",
    path: "/saleReport",
    mainMenu: true,
    icon: faFileArchive,
  },

  {
    name: "Lorry Registration",
    component: <LorryRegistration />,
    role: "pc",
    path: "/lorryRegistration",
    mainMenu: true,
    icon: faTruckFast,
  },

  {
    name: "Worker Registration",
    component: <WorkersRegistration />,
    role: "pc",
    path: "/workerRegistration",
    mainMenu: true,
    icon: faUserTie,
  },

  // {
  //   name: "AssignZone",
  //   component: <LsgdDashboard />,
  //   role: "pc",
  //   path: "/assignZone",
  //   mainMenu: true,
  //   icon: faFileExcel,
  // },

  // {
  //   name: "Sale Report",
  //   component: <SaleReport />,
  //   role: "pc",
  //   path: "/saleReport",
  //   mainMenu: true,
  //   icon: faFileExcel,
  // },
];
