import React from "react";
import {
  faAddressCard,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faLocationDot,
  faMoneyCheckDollar,
  faShip,
  faUsers,
  faCheckToSlot,faFile
} from "@fortawesome/free-solid-svg-icons";

const AdminDashboard = React.lazy(() =>
  import("../pages/adminModule/adminDashboard")
);
const CreatePorts = React.lazy(() =>
  import("../pages/adminModule/createPorts")
);
const CreatePortConservator = React.lazy(() =>
  import("../pages/adminModule/CreatePortConservator")
);
const District = React.lazy(() => import("../pages/portConservator/district"));
const SpotBookingApproval = React.lazy(() =>
  import("../pages/portConservator/spotBookingApproval")
);
const ViewRegisterdCustomers = React.lazy(() =>
  import("../pages/portConservator/viewRegisteredCustomer")
);

const SandRate = React.lazy(() => import("../pages/adminModule/sandRate"));
const AddTonLimitAdmin = React.lazy(() =>
  import("../pages/adminModule/tonLimit")
);
const Bank = React.lazy(() => import("../pages/portConservator/bank"));
const Report = React.lazy(() =>
  import("../pages/adminModule/report/components/saleReport")
);

const AdminSaleReport = React.lazy(() =>
  import("../pages/adminModule/report/components/saleReport")
);
const DateChangeHistory = React.lazy(() =>
  import("../pages/adminModule/datechageHistory")
);

const AdminSpotSaleReport = React.lazy(() =>
  import("../pages/adminModule/report/components/spotsaleReport")
);

export const adminRoutes = [
  {
    name: "DashBoard",
    component: <AdminDashboard />,
    role: "admin",
    path: "/adminDashboard",
    // mainMenu: true,
    icon: faAddressCard,
  },
  {
    name: "Port Conservators ",
    component: <CreatePortConservator />,
    role: "admin",
    path: "/createportconservators",
    mainMenu: true,
    icon: faUsers,
  },
  {
    name: "Ports ",
    component: <CreatePorts />,
    role: "admin",
    path: "/createports",
    mainMenu: true,
    icon: faShip,
  },
  {
    name: "Add District",
    component: <District />,
    role: "admin",
    mainMenu: true,
    path: "/",
    icon: faLocationDot,
  },
  // {
  //   name: "Spot Booking",
  //   component: <SpotBookingApproval  />,
  //   role: "admin",
  //   path: "/spotBookingApproval",
  //   mainMenu: true,
  //   icon: faCheckToSlot,
  // },
  {
    name: "DateChange",
    subMenuHead: true,
    icon: faCheckToSlot,
    childrens: [
      {
        name: "Change Date",
        component: <SpotBookingApproval />,
        role: "admin",
        path: "/spotBookingApproval",
      },

      {
        name: "Date Change History",
        component: <DateChangeHistory />,
        role: "admin",
        path: "/datechangeHistory",
      },
    ]
  },
 
  {
    name: "Sand Rate",
    component: <SandRate />,
    role: "admin",
    mainMenu: true,
    path: "/sandRate",
    icon: faMoneyCheckDollar,
  },
  {
    name: "Ton Limit",
    component: <AddTonLimitAdmin />,
    role: "admin",
    mainMenu: true,
    path: "/addTonLimitAdmin",
    icon: faArrowDownWideShort,
  },
  {
    name: "Add Bank",
    component: <Bank />,
    role: "admin",
    mainMenu: true,
     path: "/bank",
     icon: faMoneyCheckDollar,
  },
  {
    name: "Users",
    component: <ViewRegisterdCustomers />,
    role: "admin",
    mainMenu: true,
     path: "/users",
     icon: faUsers,
  },
  {
    name: "Reports",
    component: <Report />,
    role: "admin",
    path: "/PCReport",

    icon: faFile,
    subMenuHead: true,
    childrens: [
      {
        name: " Sale Report",
        component: <AdminSaleReport />,
        role: "admin",
        path: "/adminSaleReport",
      },
      {
        name: " Spot Sale Report",
        component: <AdminSpotSaleReport />,
        role: "admin",
        path: "/adminSpotSaleReport",
      },
    ],
    
  },
  {
    name: "Sale Report",
    component: <AdminSaleReport />,
    role: "admin",
    path: "/adminSaleReport",
  },
  {
    name: "Spot Sale Report",
    component: <AdminSpotSaleReport />,
    role: "admin",
    path: "/adminSpotSaleReport",
  },
  {
    name: "Change Date",
    component: <SpotBookingApproval />,
    role: "admin",
    path: "/spotBookingApproval",
  },

  {
    name: "Date Change History",
    component: <DateChangeHistory />,
    role: "admin",
    path: "/datechangeHistory",
  },

  // {
  //   name: "Sale Report",
  //   component: <AdminSaleReport />,
  //   role: "admin",
  //   path: "/adminSaleReport",
  //   mainMenu: true,
  //   icon: faFile,
  // },
  // {
  //   name: "Spot Sale Report",
  //   component: <AdminSpotSaleReport />,
  //   role: "admin",
  //   path: "/adminSpotSaleReport",
  //   mainMenu: true,
  //   icon: faFile,
  // },
 


];

