import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./styles/core.scss";
import "./styles/theme.scss";
import "./styles/dashboard.scss";
import { customerRoutes, authRoutes } from "./customerRoutes";
import { pcRoutes } from "./pcRoutes";
import { zoneRoutes } from "./zoneRoutes";
import PrivateRoutes from "./privateRoutes";
import Loader from "../components/loader";
import { adminRoutes } from "./adminRoutes";
import { useNavigate } from "react-router-dom";
import { LSGDRoutes } from "./LSGDRoutes";
import { useSelector } from "react-redux";
const Login = React.lazy(() => import("../pages/login"));
const NotFound = React.lazy(() => import("../components/notFound"));

export default function Routers() {
  const userToken = useSelector((state) => state.authSlice.userToken);
  const userInfo = useSelector((state) => state.authSlice.userInfo);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userToken) {
      navigate("/login");
    }
  }, []);

  function getRouteForRole(role) {
    // const userRole = userInfo?.customerDetail?.role;
    // const roleToRoute = {
    //   "port-conservator": "port-conservator",
    //   "zone-operator": "sandIssueVerification",
    //   customer: "sandBookingHistory",
    //   admin: "district",
    //   lsgd: "addMonthlyPermit",
    // };
    // return roleToRoute[role];

    const userRole = userInfo?.customerDetail?.role;

    return userRole === role ? true : false;
  }

  return (
    <div>
      <Suspense fallback={<Loader isRouterLoader={true} />}>
        <Routes>
          {/*!userToken && <Route path="/login" element={<Login />} />*/}
          <Route path="/successModal" element={<successModal />} />
          <Route path="/commonSuccessModal" element={<commonSuccessModal />} />
          {/* <Route index element={<Navigate to="/login" />} />*/}
          <Route
            exact
            path="/login"
            element={!userToken ? <Login /> : <Navigate replace to={"/"} />}
          />
          {authRoutes.map((el, i) => (
            <Route key={el.name} path={el.path} element={el.component} />
          ))}
          {/* <Route element={<PrivateRoutes />}> */}

          {getRouteForRole("port-conservator") &&
            userToken &&
            pcRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"port-conservator"}>
                    {el.component}{" "}
                  </PrivateRoutes>
                }
              />
            ))}
          {getRouteForRole("zone-operator") &&
            userToken &&
            zoneRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"zone-operator"}>
                    {el.component}{" "}
                  </PrivateRoutes>
                }
              />
            ))}
          {getRouteForRole("customer") &&
            userToken &&
            customerRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"customer"}>
                    {el.component}{" "}
                  </PrivateRoutes>
                }
              />
            ))}

          {getRouteForRole("admin") &&
            userToken &&
            adminRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"admin"}>{el.component} </PrivateRoutes>
                }
              />
            ))}

          {getRouteForRole("lsgd") &&
            userToken &&
            LSGDRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"lsgd"}>{el.component} </PrivateRoutes>
                }
              />
            ))}

          <Route path="*" element={<NotFound />} />
          {/* </Route> */}
        </Routes>
      </Suspense>
    </div>
  );
}
