import React from "react";

function Loader({ isRouterLoader }) {
  return (
    <div>
      {isRouterLoader ? (
        <div className="loader-wrapper-router">
          <div className="loader-icon"></div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader-icon"></div>
        </div>
      )}
    </div>
  );
}

export default Loader;
