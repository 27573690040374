import { localData } from "./constants";
import { logoutAction } from "../services/modules/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export const HandleLogout = () => {
  useNavigate(`/login`);
  localData.remove();
  useDispatch(logoutAction());
};
