import React from "react";
import {
  faEnvelope,
  faUserTie,
  faCalendarDays,
  faCheckToSlot,
  faChartSimple,
  faFile,
  faAddressCard,
  faGear,
  faFileExcel,
  faBook,
  faPrint,
  faTags,
  faTicket,
  faIdBadge,
  faCircleInfo,
  faTruck,
  faTruckFast,
  faTruckLoading,
  faTruckRampBox,
  faTruckArrowRight,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
const LorryRegistration = React.lazy(() =>
  import("../pages/LSGD/lorryRegistrationZo")
);

const SandIssueVerification = React.lazy(() =>
  import("../pages/zoneOperator/sandIssueVerification")
);
const SandIssueVerificationForm = React.lazy(() =>
  import(
    "../pages/zoneOperator/sandIssueVerification/components/sandIssueVerificationForm"
  )
);
const SandIssueVerifyDetails = React.lazy(() =>
  import(
    "../pages/zoneOperator/sandIssueVerification/components/sandIssueVerifyDetails"
  )
);
const SpotSandIssueVerification = React.lazy(() =>
  import("../pages/zoneOperator/spotBookingApproval")
);

const SpotSandIssueDetails = React.lazy(() =>
  import(
    "../pages/zoneOperator/spotBookingApproval/components/spotSandIssueDetails"
  )
);

//zone operator
const ZoneOperatorNormalApproval = React.lazy(() =>
  import("../pages/zoneOperator/normalBookingApproval/")
);
const ZoneOperatorSpotApproval = React.lazy(() =>
  import("../pages/zoneOperator/spotBookingApproval/")
);
const ZoneDashboard = React.lazy(() =>
  import("../pages/zoneOperator/dashboard")
);

const SandIssueReprint = React.lazy(() =>
  import("../pages/zoneOperator/sandIssueReprint")
);
const ZoneLorryDetails = React.lazy(() =>
  import("../pages/zoneOperator/viewLorryDetails")
);
const VehiclePassDetails = React.lazy(() =>
  import("../pages/zoneOperator/viewPassDetails")
);
const ZoneViewSalereport = React.lazy(() =>
  import("../pages/zoneOperator/viewSaleReport")
);
const ZoneViewSalereportSpotbooking = React.lazy(() =>
  import("../pages/zoneOperator/viewSaleReport(sportbooking)")
);

/** Code by Akhil */
const ZonePass = React.lazy(() =>
  import("../pages/zoneOperator/sandPassDetails")
);
/**------end here------ */

const ZoneSandIssueVerification = React.lazy(() =>
  import("../pages/zoneOperator/sandIssueVerification")
);
export const zoneRoutes = [
  {
    name: "Sand-Pass",
    component: <ZonePass />,
    role: "zone",
    path: "/sand-pass",
    mainMenu: true,
    icon: faTicket,
  },

  {
    name: "Sand Issue Verification",
    component: <SandIssueVerification />,
    role: "zone",
    path: "/",
    mainMenu: true,
    icon: faCheckToSlot,
  },

  {
    name: "Sand Reprint",
    component: <SandIssueReprint />,
    role: "zone",
    path: "/zoneSandReprint",
    mainMenu: true,
    icon: faPrint,
  },
  {
    name: "Spot Sand Issue Details",
    component: <SpotSandIssueDetails />,
    role: "zone",
    path: "/spotSandIssueDetails",
    mainMenu: true,
    icon: faCircleInfo,
  },
  {
    name: "Lorry Details",
    component: <ZoneLorryDetails />,
    role: "zone",
    path: "/ZoneLorryDetails",
    mainMenu: true,
    icon: faTruck,
  },
  {
    name: "Vehicle Pass Details",
    component: <VehiclePassDetails />,
    role: "zone",
    path: "/vehiclePassDetails",
    mainMenu: true,
    icon: faTruckArrowRight,
  },
  {
    name: "Sale Report",
    component: <ZoneViewSalereport />,
    role: "zone",
    path: "/zoneSaleReport",
    mainMenu: true,
    icon: faFile,
  },
  {
    name: " Spot Sale Report",
    component: <ZoneViewSalereportSpotbooking />,
    role: "zone",
    path: "/zoneSaleReportSpot",
    mainMenu: true,
    icon: faFileUpload,
  },
  {
    name: "Spot Booking Approval",
    component: <SpotSandIssueVerification />,
    role: "zone",
    path: "/spotSandIssueVerification",
    mainMenu: true,
    icon: faFileUpload,
  },
  {
    name: "Lorry Registration",
    component: <LorryRegistration />,
    role: "pc",
    path: "/lorryRegistration",
    mainMenu: true,
    icon: faTruckFast,
  },

  {
    name: "Sand Issue Verification Form",
    component: <SandIssueVerificationForm />,
    role: "zone",
    path: "/sandIssueVerificationform",
  },
  {
    name: "Spot Sand Issue Verification",
    component: <SpotSandIssueVerification />,
    role: "zone",
    path: "/spotSandIssueVerification",
  },

  {
    name: "sandIssueVerifyDetails",
    component: <SandIssueVerifyDetails />,
    role: "zone",
    path: "/sandIssueVerifyDetails",
  },

  {
    name: "Zone Operator Normal Approval",
    component: <ZoneOperatorNormalApproval />,
    role: "zone",
    path: "/zoneOperatorNormalApproval",
  },
  {
    name: "Zone Operator Spot Approval",
    component: <ZoneOperatorSpotApproval />,
    role: "zone",
    path: "/zoneOperatorSpotApproval",
  },
  {
    name: "Zone Dashboard",
    component: <ZoneDashboard />,
    role: "zone",
    path: "/ZoneDashboard",
  },
  // {
  //   name: "zoneSaleReport",
  //   component: <ZoneViewSalereport />,
  //   role: "zone",
  //   path: "/zoneSaleReport",
  // },

  {
    name: "Zone Sand Issue Verification",
    component: <ZoneSandIssueVerification />,
    role: "zone",
    path: "/zoneSandIssueVerification",
  },
];
